import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import Copyright from '../components/Copyright';
import PageLoader from '../components/PageLoader';
import CustomModal from '../components/CustomModal';
import { useCookies } from 'react-cookie';

import { useHistory } from "react-router-dom";


import { httpPost } from '../network/service';



import { Config } from '../config/constant';

// IMAGES
import loginBackground from '../assets/img/login-bg.jpg';
import OESPH_ICON from '../assets/img/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${loginBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AdminLogin() {
  
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['admin_session']);

  // MODAL
  const [modalMessage, setModalMessage] = useState('');
  const [modalState, setModalState] = useState(false);
  const [modalResult, setModalResult] = useState(false);

  useEffect(() => {

  });

  const loginAdmin = async () =>{
    let headers = {
        'Authorizationkey' : Config.authorization
    }
    setLoading(true);
    let response = await httpPost('/auth/loginAdmin', {
        'email': email,
        'password':  password
    }, headers);

    setLoading(false);
   
    if(!response.result){

      setModalState(true);
      setModalResult(response.result);
      setModalMessage(response.message);
    }else{
      setCookie('admin_session', response.token);
      console.log(history);
      history.push("/admin/dashboard");

    }
  }

  const onClose = () =>{
    setModalState(false);
  }
  
  const  handleSubmit = (evt) =>{
    evt.preventDefault();
    loginAdmin();
  }

  return (
    <div >
      <PageLoader loading={isLoading} />
      <CustomModal onClose={onClose} open={modalState} result={modalResult} message={modalMessage} type={"message"} />
        <Grid container component="main" className={classes.root}>
          
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Avatar src={OESPH_ICON} />
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isLoading}
                >
                  {!isLoading ? "SIGN IN" : (<div><CircularProgress size={25} /></div>)}
                </Button>
                
                {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid> */}
                <Box mt={2}>
                  <Copyright />
                </Box>
              </form>
            </div>
          </Grid>

        </Grid>
    </div>

  );
}

import React, {useEffect, useState, useCallback, useRef} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CircularProgress from '@material-ui/core/CircularProgress';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CustomModal from '../components/CustomModal';

import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
import PageLoader from './PageLoader';
 
import Moment from 'react-moment';
import { Paper } from '@material-ui/core';
import Loader from 'react-loader';



const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formControlDate: {
      margin: theme.spacing(1),
      minWidth: 80
  },
  margin: {
    margin: theme.spacing(0),
    padding: 16
  },
  assignAccount: {
    display: 'flex',
    flexDirection: 'column'
  },
  formRow: {
    flexDirection: 'row', 
    display: 'flex', 
    justifyContent: 'space-between'
  },
  formRowDate:{
    flexDirection: 'row', 
    display: 'flex', 
    justifyContent: 'flex-start'
  }
}));

export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [employeeData, setAttendanceData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);
    const [subdepartmentData, setSubDepartmentData] = useState([]);
    const [member, setMember] = useState('');
    const [department, setDepartment] = useState('');

    const descriptionElementRef = useRef(null);
    const rfidLabel = useRef(null);
    const [rfidWidth, setRfidWidth] = useState(0);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [idnumber, setIdNumber] = useState('');
    const [email, setEmail] = useState('');

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [rfidcode, setRfidCode] = useState('');

    const [monthData, setMonthData] = useState([
    ]);
    const [daysData, setDaysData] = useState([
    ]);
    const [yearData, setYearData] = useState([
    ]);

    const [rfidData, setRfidData] = useState([]);

    const [modalState, setModalState] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalResult, setModalResult] = useState(false);

    const [createdAccount, setCreatedAccount] = useState(false);

    const [department_value, setDepartmentValue] = useState('');
    const [subdepartment_value, setSubDepartmentValue] = useState('');
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        let monthCollection = [];
        for (var i = 0; i < 12; i++) {
            monthCollection[i] = { key: moment().set({month: i}).format("MM") , value: moment().set({month: i}).format("MMMM")};
        }
        let dayCollection = [];
        for (var i = 1; i <= 31; i++) {
            dayCollection[i] = { key: i , value: i};
        }
        let yearCollection = [];
        let currYear = moment().format('YYYY');
        for (var i = 2000; i <= parseInt(currYear); i++) {
            yearCollection.push({ key: moment().set({year: i}).format("YYYY") , value: moment().set({year: i}).format("YYYY")});
        }
        yearCollection =  yearCollection.reverse();

        setMonthData(monthCollection);
        setDaysData(dayCollection);
        setYearData(yearCollection);

        
        getEmployeesReport(params);
        getDepartment();
        getSubDepartment();
        getRfidCodes();
    }, []);

    const getEmployeesReport = ( async (data = {}) =>{
        let params = {
        };
        params = Object.assign(params, data);

        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getEmployeesReport', params, headers);

        if(response.result){
            setAttendanceData(response.data);
        }else{
            setAttendanceData([]);
        }

    });

    const getDepartment =  ( async () =>{
        let params = {
            selection: 'true'
        };
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getDepartment', params, headers);


        if(response.result){
            setDepartmentData(response.data);
        }else{
            // setAttendanceData([]);
        }
    });

    const getSubDepartment =  ( async () =>{
        let params = {
        };
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getSubDepartment', params, headers);


        if(response.result){
            setSubDepartmentData(response.data);
        }else{
            // setAttendanceData([]);
        }
    });

    

    const handleDepartmentChange = useCallback(event => {
        setDepartment(event.target.value);
    }, [department]);

    const handleSubmit = (evt) =>{
        evt.preventDefault();

    }

    // RENDERING OF ATTENDANCE TABLE
    function renderEmployees(){

        if(employeeData.length > 0){
            return (
                employeeData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                    <TableCell>{row.rfid_code}</TableCell>
                    <TableCell>
                        <Link href="#" onClick={preventDefault}>
                            {row.name}
                        </Link>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.department_name} - {row.sub_department}</TableCell>
                    <TableCell align="right">
                        {moment(row.date_hired).format("MMM D, Y hh:mm A") }

                    </TableCell>
                    </TableRow>
                ))
            );
        }
        return (<TableRow colSpan={12} style={{textAlign: 'center'}}>No result found.</TableRow>);
    }

    const searchRequest = useCallback(async () => {
        let params: any = {};
        if(member !== ''){
            params.member = member;
        }
        if(department != ''){
            // let selectedItem = departmentData.filter(function(item) {
            //     return (item.department_name + ' - ' + item.sub_department_name) == department
            // });
            let selectedItem = departmentData.filter(function(item) {
                return (item.department_name) == department
            });
            if(selectedItem[0]){
                params.department = selectedItem[0]['id'];
                // params.sub_department = selectedItem[0]['sub_department'];
            }
        }
        // console.log(params)
        await getEmployeesReport(params);
    }, [department,member]) ;

    const renderDepartment = () =>{
        if(departmentData.length > 0){
            return (departmentData.map((row)=>{
                return (<option  key={row.id}>{row.department_name}</option>);
            }));
        }
    }

    const renderSubDepartment = () =>{
        if(subdepartmentData.length > 0){
            return (subdepartmentData.map((row)=>{
                return (<option  key={row.id}>{row.name}</option>);
            }));
        }
    }
    

    const renderMonth = () =>{
        if(monthData.length > 0){
            return (monthData.map((row)=>{
                return (<option  key={row.id}>{row.value}</option>);
            }));
        }
    }

    const renderDays = () =>{
        if(daysData.length > 0){
            return (daysData.map((row)=>{
                return (<option  key={row.id}>{row.value}</option>);
            }));
        }
    }

    const renderYear = () =>{
        if(yearData.length > 0){
            return (yearData.map((row)=>{
                return (<option  key={row.id}>{row.value}</option>);
            }));
        }
    }


    const [open, setOpen] = useState(false);
    const handleAssign = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCreate = async () =>{

        // let month = moment().month(month).format("M");
        if(rfidcode.length < 10){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide a rfid code.');
            }, 200);
            return;
        }

        if(idnumber == '' || idnumber == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide id number.');
            }, 200);
            return;
        }

        if(department_value == '' || department_value == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide department.');
            }, 200);
            return;
        }


        if(subdepartment_value == '' || subdepartment_value == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide position.');
            }, 200);
            return;
        }


        if(email == '' || email == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide email address.');
            }, 200);
            return;
        }

        if(firstname == '' || firstname == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide firstname.');
            }, 200);
            return;
        }

        if(lastname == '' || lastname == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide lastname.');
            }, 200);
            return;
        }


        if(year == '' || year == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide year for date hired.');
            }, 200);
            return;
        }

        if(month == '' || month == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide month for date hired.');
            }, 200);
            return;
        }

        if(day == '' || day == null){

            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(false);
                setModalMessage('Provide day for date hired.');
            }, 200);
            return;
        }
        let department_key = '';
        let subdepartment_key = '';
        for(let i in departmentData){
            if(departmentData[i]['department_name'] == department_value){
                department_key = departmentData[i]['id'];
                break;
            }
        }
        for(let i in subdepartmentData){
            if(subdepartmentData[i]['name'] == subdepartment_value){
                subdepartment_key = subdepartmentData[i]['id'];
                break;
            }
        }

        let headers = {
            'Authorizationkey' : Settings.token
        };
        setLoading(true);
        let response_record = await httpPost('/api/recordRFID', {
            rfid_code: rfidcode
        }, headers);
        
        if(!response_record.result){
            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(response_record.result);
                setModalMessage(response_record.message);
            }, 200);
            setLoading(false);
            return;
        }


        let new_date_hire = moment().year(year).month(month).date(day).format('YYYY-MM-DD HH:mm:ss');

        // let month = moment().month(`${year}-${month}-${day}`).format("M");
        let params = {
            email: email,
            date_hired: new_date_hire,
            id_number: idnumber,
            firstname: firstname,
            lastname: lastname,
            rfid_code: rfidcode,
            department: department_key,
            subdepartment: subdepartment_key
        };
        // console.log(params);
        // return false;
        let response = await httpPost('/admin/createUser', params, headers);
        
        if(!response.result){
            await setOpen(false);
            setTimeout(()=>{
                setModalState(true);
                setModalResult(response.result);
                setModalMessage(response.message);
            }, 200);
            setLoading(false);
            return;
        }
        setLoading(false);

        await setOpen(false);
        setTimeout(()=>{
            setModalState(true);
            setModalResult(response.result);
            setModalMessage(response.message);
            setCreatedAccount(true);
        }, 200);
    };


    
    const handleRfidChange = useCallback(event => {
      setRfidCode(event.target.value);
    }, [rfidcode]);

    const handleDepartmentValue = useCallback(event => {
        setDepartmentValue(event.target.value);
    }, [department_value]);

    const handleSubDepartmentValue = useCallback(event => {
        setSubDepartmentValue(event.target.value);
    }, [subdepartment_value]);


    const handleMonthChange = useCallback(event => {
        setMonth(event.target.value);
      }, [month]);

    const handleDayChange = useCallback(event => {
        setDay(event.target.value);
      }, [day]);

    const handleYearChange = useCallback(event => {
        setYear(event.target.value);
    }, [year]);

    const renderRfidData = () =>{
        if(rfidData.length > 0){
            return (rfidData.map((row)=>{
                return (<option  key={row.id}>{row.rfid_code}</option>);
            }));
        }
    }

    const getRfidCodes = ( async (data = {}) =>{
      let params = {
      };
      params = Object.assign(params, data);

      let headers = {
          'Authorizationkey' : Settings.token
      };
      let response = await httpPost('/admin/getRfid', params, headers);

      if(response.result){
          setRfidData(response.data);
      }else{
          setRfidData([]);
      }

    });

    const onClose = () =>{
        setModalState(false);

        if(!createdAccount){
            setOpen(true);
        }else{
            window.location.reload();
        }
    };

    return (
        <React.Fragment>
            
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Employees
            </Typography>
            <Grid container spacing={3}>
                <CustomModal onClose={onClose} open={modalState} result={modalResult} message={modalMessage} type={"message"} />

                <Grid item lg={12} md={12} xs={8}>
                    <FormControl className={classes.formControl}>
                        <TextField id="outlined-search" label="Search..." type="search"  value={member} variant="outlined" fullWidth onChange={e => setMember(e.target.value)} />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-department-native" fullWidth>
                            Department
                        </InputLabel>
                        <Select
                        native
                        value={department}
                        onChange={handleDepartmentChange}
                        labelWidth={labelWidth}
                        inputProps={{
                            name: 'department',
                            id: 'outlined-department-native',
                        }}
                        >
                        <option aria-label="None" value="" />
                        {renderDepartment()}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.margin}
                            onClick={searchRequest}
                        >
                            <SearchIcon ></SearchIcon>
                        </Button> 
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={classes.margin}
                            onClick={handleAssign}
                        >
                            <AddBoxIcon ></AddBoxIcon>
                        </Button> 
                    </FormControl>

                </Grid>  
                
            </Grid>

            
        <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>RFID</TableCell>
                <TableCell className={classes.tableHeader}>NAME</TableCell>
                <TableCell className={classes.tableHeader}>EMAIL</TableCell>
                <TableCell className={classes.tableHeader}>DEPARTMENT</TableCell>
                <TableCell align="right" className={classes.tableHeader}>DATE REGISTERED</TableCell>
                {/* <TableCell className={classes.tableHeader}>WORKING TYPE</TableCell>
                <TableCell className={classes.tableHeader}>TYPE</TableCell>
                */}
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderEmployees()
            }
            </TableBody>
        </Table>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={'xs'}
          fullWidth={true}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
            <PageLoader loading={isLoading} />
          <DialogTitle id="scroll-dialog-title">Assign Account</DialogTitle>
          <DialogContent className={classes.assignAccount} >

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" fullWidth>
                      RFID Code
                  </InputLabel>
                  <Select
                  native
                  value={rfidcode}
                  onChange={handleRfidChange}
                  labelWidth={labelWidth}
                  fullWidth
                  inputProps={{
                      name: 'rfid',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderRfidData()}
                  </Select>
                </FormControl> */}
                
            <FormControl className={classes.formControl}>
                  <TextField label="RFID Code" type="text" variant="outlined" fullWidth value={rfidcode} onChange={e => setRfidCode(e.target.value)} disabled={rfidcode.length == 10}  />
            </FormControl>
            <FormControl className={classes.formControl}>
                  <TextField label="ID Number" type="text" variant="outlined" fullWidth value={idnumber} onChange={e => setIdNumber(e.target.value)}  />
            </FormControl>

             <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" fullWidth>
                      Department
                  </InputLabel>
                  <Select
                  native
                  value={department_value}
                  onChange={handleDepartmentValue}
                  labelWidth={labelWidth}
                  fullWidth
                  inputProps={{
                      name: 'department-value',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderDepartment()}
                  </Select>
            </FormControl>


            <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" fullWidth>
                      Position
                  </InputLabel>
                  <Select
                  native
                  value={subdepartment_value}
                  onChange={handleSubDepartmentValue}
                  labelWidth={labelWidth}
                  fullWidth
                  inputProps={{
                      name: 'position-value',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderSubDepartment()}
                  </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
                <TextField label="Email Address" type="text" variant="outlined" fullWidth value={email} onChange={e => setEmail(e.target.value)}  />
            </FormControl>

            <div className={classes.formRow}>
            <FormControl className={classes.formControl}>
                <TextField label="Firstname" type="text" variant="outlined" value={firstname} fullWidth onChange={e => setFirstname(e.target.value)}  />
            </FormControl>

            <FormControl className={classes.formControl}>
                <TextField  label="Lastname" type="text" variant="outlined" fullWidth value={lastname} onChange={e => setLastname(e.target.value)}  />
            </FormControl>
            </div>
            <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" fullWidth style={{paddingLeft: 10}}>
                Date Hired
            </InputLabel>
              <div className={classes.formRowDate}>
                
                <FormControl variant="outlined" className={classes.formControlDate}>
                  <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" fullWidth>
                      Month
                  </InputLabel>
                  <Select
                  native
                  value={month}
                  onChange={handleMonthChange}
                  labelWidth={80}
                  fullWidth
                  inputProps={{
                      name: 'rfid',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderMonth()}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControlDate}>
                  <InputLabel ref={rfidLabel} htmlFor="outlined-rfid-native" >
                      Day
                  </InputLabel>
                  <Select
                  native
                  value={day}
                  onChange={handleDayChange}
                  labelWidth={25}
                  style={{width: 80}}
                  inputProps={{
                      name: 'rfid',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderDays()}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControlDate}>
                  <InputLabel htmlFor="outlined-rfid-native" fullWidth>
                      Year
                  </InputLabel>
                  <Select
                  native
                  value={year}
                  onChange={handleYearChange}
                  labelWidth={35}
                  fullWidth
                  inputProps={{
                      name: 'rfid',
                      id: 'outlined-rfid-native',
                  }}
                  >
                  <option aria-label="None" value="" />
                    {renderYear()}
                  </Select>
                </FormControl>
                
                </div>
              

          </DialogContent>

            <DialogActions>
                    <Button onClick={handleClose}  variant="contained" >
                    Cancel
                    </Button>
                    <Button onClick={handleCreate}  variant="contained" color="primary" disabled={isLoading} >
                    {isLoading ? (<div><CircularProgress size={25} /></div>) : 'Create'}
                    </Button>
            </DialogActions>
        </Dialog>
            
        </React.Fragment>
    );
}
import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import CustomModal from '../components/CustomModal';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
import Moment from 'react-moment';

const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  },
  margin:{
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  assignAccount: {
    display: 'flex',
    flexDirection: 'column'
  },
  formRow: {
    flexDirection: 'row', 
    display: 'flex', 
    justifyContent: 'space-between'
  },
}));

export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();

    const [departmentData, setDepartmentData] = useState([]);

    const [department, setDepartmentName] = useState('');
    const [open, setOpen] = useState(false);

    const [modalState, setModalState] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalResult, setModalResult] = useState(false);

    const [createDepartment, setCreatedDepartment] = useState(false);

    useEffect(() => {
        getDepartment(params);
    }, []);

    async function getDepartment(data = {}){
        let params = {
        };
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getDepartment', params, headers);

        if(response.result){
            setDepartmentData(response.data);
        }

    }

    function navigateAttendance(event){
        event.preventDefault();
        history.push('/admin/attendance');
    }

    // RENDERING OF ATTENDANCE TABLE
    function renderDepartment(){

        if(departmentData.length > 0){
            return (
                departmentData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                    <TableCell>
                        {row.id}
                    </TableCell>
                    <TableCell>{row.department_name}</TableCell>
                    {/* <TableCell align="center">
                        <Button variant="outlined" size="small" color="primary" className={classes.margin}>
                        Edit
                        </Button>
                        <Button variant="outlined" size="small" color="secondary" className={classes.margin}>
                        REMOVE
                        </Button>
                    </TableCell> */}
                    </TableRow>
                ))
            );
        }
        return null;
    }

    
      const handleClose = () => {
        setOpen(false);
      };
  
      const handleCreate = async () =>{

          let params = {
              name: department
          };
          console.log(params);
          // return false;
  
          let headers = {
              'Authorizationkey' : Settings.token
          };
          let response = await httpPost('/admin/createDepartment', params, headers);
          
          if(!response.result){
              await setOpen(false);
              setTimeout(()=>{
                  setModalState(true);
                  setModalResult(response.result);
                  setModalMessage(response.message);
              }, 200);
              return;
          }
  
          await setOpen(false);
          setTimeout(()=>{
              setModalState(true);
              setModalResult(response.result);
              setModalMessage(response.message);
              setCreatedDepartment(true);
          }, 200);
      };
  

    const onAddDepartment = () => {
        setOpen(true);
    }

    const onClose = () =>{
        setModalState(false);

        if(!setCreatedDepartment){
            setOpen(true);
        }else{
            window.location.reload();
        }
    };
    return (
        <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Department
        </Typography>
        <CustomModal onClose={onClose} open={modalState} result={modalResult} message={modalMessage} type={"message"} />

        <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>ID</TableCell>
                <TableCell className={classes.tableHeader}>NAME</TableCell>
                {/* <TableCell className={classes.tableHeader} align="center">ACTION</TableCell> */}
                <TableCell className={classes.tableHeader}>
                <Button variant="outlined" size="small" color="primary" className={classes.margin} onClick={onAddDepartment}>
                        Add
                </Button>

                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderDepartment()
            }
            </TableBody>
        </Table>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={'xs'}
          fullWidth={true}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Create Department</DialogTitle>
          <DialogContent className={classes.assignAccount} >

              <FormControl className={classes.formControl}>
                  <TextField label="Department" type="text" variant="outlined" fullWidth value={department} onChange={e => setDepartmentName(e.target.value)}  />
              </FormControl>
              

          </DialogContent>
          <DialogActions>
                <Button onClick={handleClose}  variant="contained"  >
                  Cancel
                </Button>
                <Button onClick={handleCreate}  variant="contained"
                            color="primary" >
                  Create
                </Button>
              </DialogActions>
          </Dialog>
        </React.Fragment>
    );
}
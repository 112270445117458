import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Chip from '@material-ui/core/Chip';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  }
}));

export default function ({origin, params}) {
  const classes = useStyles();
  
  const [historyData, setHistory] = useState([]);

  useEffect(() => {
      if(origin == 'dashboard'){
        
      }else if (origin == 'history'){
          
      }
      getHistory(params);
  }, []);

  async function getHistory(data = {}){
      let params = {
      };
      params = Object.assign(params, data);
      let headers = {
          'Authorizationkey' : Settings.token
      };
      let response = await httpPost('/admin/getAdminHistory', params, headers);


      if(response.result){
        setHistory(response.data);
      }

  }

  function renderHistory(){

    if(historyData.length > 0){
        return (
            historyData.map(row => (
                <TableRow key={randomId(20, 'aA0')}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                    {moment(row.date_created).format("MMM D, Y") }
                </TableCell>
                </TableRow>
            ))
        );
    }
    return null;
}


  return (
    <React.Fragment>
       <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Recent History
        </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>LOG</TableCell>
            <TableCell className={classes.tableHeader}>DATE CREATED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderHistory()}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
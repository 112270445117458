import React, {useEffect, useState, useCallback} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImportExportIcon from '@material-ui/icons/ImportExport';


import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
import Moment from 'react-moment';
import { Paper } from '@material-ui/core';
import { CSVLink, CSVDownload } from "react-csv";

const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold',
      
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  margin: {
    margin: theme.spacing(0),
    padding: 16
  },
  headerContainer: {
    display: 'flex', 
    alignItems: 'center'
  }
}));

const headers = [
    { label: "Name", key: "name" },
    { label: "IN", key: "in" },
    { label: "OUT", key: "out" },
    { label: "Date", key: "date"}
];

const default_year = '2021';

export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();
    const currentDate = moment().format('YYYY-MM-DD');

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [employeeData, setAttendanceData] = useState([]);
    const [employeeItemsData, setEmployeeItemsData] = useState([]);

    const [department, setDepartment] = useState('');
    const [range, setRange] = useState('');
    const [rangeKey, setRangeKey] = useState('');
    const [rangeData, setRangeData] = useState([]);
    const [year, setYear] = useState(default_year);
    const [yearKey, setYearKey] = useState(default_year);
    const [yearData, setYearData] = useState([
        {key: '2021', value: '2021'}
    ]);

    const [quarter, setQuarter] = useState('');
    const [quarterKey, setQuarterKey] = useState('');
    const [quarterData, setQuarterData] = useState([
        {key: '1', value: '1st Cutoff'},
        {key: '2', value: '2nd Cutoff'}
    ])
    const [insideItem, setInsideItem] = useState(false);
    const [member, setMember] = useState('');
    const [ attendanceDownloadData, setAttendanceDownloadData] = useState([]);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        getEmployeesReport(params);
        var end = moment().format("MM");
        // console.log(end);

        let dateCollection = [];
        for(let i =0; i <= parseInt(end); i++){
            dateCollection[i] = { key: moment().set({month: i}).format("MM") , value: moment().set({month: i}).format("MMMM")};
        }


        setRangeData(dateCollection);
    }, []);

    async function getEmployeesReport(data = {}){
        let params = {
            'year': moment().format('YYYY'),
            'month': moment().format('MM')
        };
        if(data['year']){
            params['year'] = data['year'];
        }

        if(data['month']){
            params['month'] = data['month'];
        }
        if(data['quarter']){
            params['quarter'] = data['quarter'];
        }

        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getAttendanceSummary', params, headers);
        if(response.result){
            setAttendanceData(response.data);
        }else{
            setAttendanceData([]);
        }
        return response;
    }

    async function getEmployeesItemReport(data = {}){
        let params = {
            'year': moment().format('YYYY'),
            'month': moment().format('MM')
        };
        if(data['year']){
            params['year'] = data['year'];
        }

        if(data['month']){
            params['month'] = data['month'];
        }

        if(data['member_id']){
            params['member_id'] = data['member_id'];
        }

        if(data['member']){
            params['member'] = data['member'];
        }

        if(data['quarter']){
            params['quarter'] = data['quarter'];
        }

        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getAttendanceItems', params, headers);
        if(response.result){
            setEmployeeItemsData(response.data);
            let downloadableData = [];
            let { data } = response;
            for(let i in data){
                downloadableData.push({
                    date:  moment(data[i]['date']).format("YYYY-MM-DD"),
                    name: `${data[i]['firstname']} ${data[i]['lastname']}`,
                    in: data[i]['in'],
                    out: data[i]['out']
                });
            }

            setAttendanceDownloadData(downloadableData); 
            
        }else{
            setEmployeeItemsData([]);
            setAttendanceDownloadData([]);
        }

        return response;
    }

    

    const handleMonthChange = async (event) => {
        if(event.target.value == null) return false;

        setRange(event.target.value);
        let selectedItem = rangeData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0] != null){
            if(selectedItem[0]['key']){
                setRangeKey(selectedItem[0]['key']);
                let params: any = {};
                if(selectedItem[0]['key'] != ''){
                    params.month = selectedItem[0]['key'];
                }
                params.year = yearKey;

                if(quarterKey != null && quarterKey != ''){
                    params.quarter = quarterKey;
                } 

                if(insideItem){
                    if(member !== ''){
                        params.member = member;
                    }
                    await getEmployeesItemReport(params);
                }else{
                await getEmployeesReport(params);
                }
            }
        }
    };

    const handleYearChange = async (event) => {
        setYear(event.target.value);
        let selectedItem = yearData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0] != null){
            if(selectedItem[0]['key']){
                setYearKey(selectedItem[0]['key']);
                let params: any = {};
                if(selectedItem[0]['key'] != ''){
                    params.year = selectedItem[0]['key'];
                }
                if(rangeKey != null){
                    params.month = rangeKey;
                }

                if(quarterKey != null && quarterKey != ''){
                    params.quarter = quarterKey;
                } 

                if(insideItem){
                    if(member !== ''){
                        params.member = member;
                    }
                    await getEmployeesItemReport(params);
                }else{
                await getEmployeesReport(params);
                }
            }
        }
    };

    const handleQuarterChange = async (event) => {
        setQuarter(event.target.value);
        let selectedItem = quarterData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0] != null){
            if(selectedItem[0]['key']){
                setQuarterKey(selectedItem[0]['key']);
                let params: any = {};
                if(selectedItem[0]['key'] != ''){
                    params.quarter = selectedItem[0]['key'];
                }
                if(rangeKey != null && range != ''){
                    params.month = rangeKey;
                }
                if(yearKey != null && year != ''){
                    params.year = yearKey;
                }

                if(insideItem){
                    if(member !== ''){
                        params.member = member;
                    }
                    await getEmployeesItemReport(params);
                }else{
                await getEmployeesReport(params);
                }
            }
        }
    };

    const renderYearRange = () =>{
        if(yearData.length > 0){
            return (yearData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }
    };


    const renderQuarterRange = () =>{
        if(quarterData.length > 0){
            return (quarterData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }
    };


    const renderMonthRange = () =>{
        if(rangeData.length > 0){
            return (rangeData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }
    };


    const handleSubmit = (evt) =>{
        evt.preventDefault();

    }

    const onClickDate = async (value) => {
        setInsideItem(true);
        let ymd = moment(value).format("YYYY-MM-DD");
        let params = {
            ymd: ymd
        };
        getEmployeesItemReport(params);
    }

    const onFilterName = (item) => {
        let member_id = item.user_id;
        let params = {
            member_id: member_id
        };

        if(range != '' && range != null){
            params.month = rangeKey;
        }
        if(yearKey != null && yearKey != ''){
            params.year = yearKey;
        }

        if(quarterKey != null && quarterKey != ''){
            params.quarter = quarterKey;
        }
        getEmployeesItemReport(params);
    };

    const onPressInsideBack = () =>{
        setInsideItem(false);
    }

    const searchRequest = useCallback(async () => {
        // let ymd = moment(range).format("MM") || '';
        let param = {
            member: member
        }
        if(range != '' && range != null){
            param.month = rangeKey;
        }
        if(yearKey != null && yearKey != ''){
            param.year = yearKey;
        }

        if(quarterKey != null && quarterKey != ''){
            param.quarter = quarterKey;
        } 
        getEmployeesItemReport(param);
    }, [range, member]) ;

    const onClickDownloadCSV = async (event, done) => {
        let ymd = moment(range).format("MM") || '';
        let param = {
            member: member
        }
        if(range != '' && range != null){
            param.month = rangeKey;
        }
        if(yearKey != null && yearKey != ''){
            param.year = yearKey;
        }

        if(quarterKey != null && quarterKey != ''){
            param.quarter = quarterKey;
        } 

        let response = await getEmployeesItemReport(param);
        done();
        
    };


    const onSortDate = useCallback( async () => {
        let employeeDataCopy = employeeData.reverse();
        let employeeItemsDataCopy = employeeItemsData.reverse();

        setAttendanceData(employeeDataCopy);
        setEmployeeItemsData(employeeItemsDataCopy);


    }, [employeeData, employeeItemsData]);

    // RENDERING OF ATTENDANCE TABLE
    function renderSummary(){

        if(employeeData.length > 0){
            return (
                employeeData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                        <TableCell>
                            <Link href="#" onClick={() => {onClickDate(row.date)}}>
                                {moment(row.date).format("YYYY-MM-DD")}
                            </Link>
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}> {row.user_present} / {row.total}</TableCell>
                    </TableRow>
                ))
            );
        }
        return (<TableRow colSpan={12} >No result found.</TableRow>)
    }

    function renderAttendanceItem(){
        if(employeeItemsData.length > 0){
            return (
                employeeItemsData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                        
                        <TableCell>
                            <Link href="#" onClick={() => {onFilterName(row)}}>
                            {row.firstname} {row.lastname}
                            </Link>
                        </TableCell>
                        <TableCell align="center">{row.in}</TableCell>
                        <TableCell align="center">{row.out}</TableCell>
                        <TableCell>{moment(row.date).format("YYYY-MM-DD")}</TableCell>
                    </TableRow>
                ))
            );
        }
        return (<TableRow colSpan={12} >No result found.</TableRow>)
    }

    return (
        <React.Fragment>
            <div className={classes.headerContainer}>
                {insideItem == true && <IconButton color="primary" aria-label="upload picture" component="span" align="center" onClick={onPressInsideBack}>
                    <ArrowBackIcon />
                </IconButton>}
                <Typography component="h2" variant="h6" color="primary">
                    Summary
                </Typography>
            </div>
            
            <Grid container spacing={3}>

                <Grid item lg={12} md={12} xs={8}>
                    {insideItem && <FormControl className={classes.formControl}>
                        <TextField id="outlined-search" label="Search by name..." type="search"  value={member} variant="outlined" fullWidth onChange={e => setMember(e.target.value)} />
                    </FormControl>}
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-department-native" fullWidth>
                                Month
                            </InputLabel>
                            <Select
                            native
                            value={range}
                            onChange={handleMonthChange}
                            labelWidth={labelWidth}
                            inputProps={{
                                name: 'department',
                                id: 'outlined-department-native',
                            }}
                            >
                            <option aria-label="None" value="" />
                            {renderMonthRange()}
                            </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-year-native" fullWidth>
                                Year
                            </InputLabel>
                            <Select
                            native
                            value={year}
                            onChange={handleYearChange}
                            labelWidth={labelWidth}
                            inputProps={{
                                name: 'year',
                                id: 'outlined-year-native',
                            }}
                            >
                            {renderYearRange()}
                            </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-quarter-native" fullWidth>
                                Cutoff
                            </InputLabel>
                            <Select
                            native
                            value={quarter}
                            onChange={handleQuarterChange}
                            labelWidth={labelWidth}
                            inputProps={{
                                name: 'quarter',
                                id: 'outlined-quarter-native',
                            }}
                            >
                            <option aria-label="None" value="" />
                            {renderQuarterRange()}
                            </Select>
                    </FormControl>
                    {insideItem && <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.margin}
                            onClick={searchRequest}
                        >
                            <SearchIcon ></SearchIcon>
                        </Button> 
                    </FormControl>}
                    {insideItem &&<FormControl className={classes.formControl}>
                        <CSVLink 
                            data={attendanceDownloadData} 
                            headers={headers} 
                            asyncOnClick={true}
                            onClick={onClickDownloadCSV}
                            filename={`attendance-report-${currentDate}.csv`}
                            className={classes.margin}>
                            Download CSV
                        </CSVLink>
                    </FormControl>}
                   
                </Grid>  
                
            </Grid>
        {!insideItem && <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>DATE</TableCell>
                <TableCell className={classes.tableHeader} align='center'>Present / Total Employee</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderSummary()
            }
            </TableBody>
        </Table>}


        {/* INSIDE ITEMS */}

        {insideItem && <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>NAME</TableCell>
                <TableCell className={classes.tableHeader} align='center'>IN</TableCell>
                <TableCell className={classes.tableHeader} align='center'>
                    OUT
                </TableCell>
                <TableCell className={classes.tableHeader}>
                    <a href="#" style={{display: 'flex', color: '#3f51b5'}} onClick={onSortDate}>
                        DATE
                        <ImportExportIcon />
                    </a>
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderAttendanceItem()
            }
            </TableBody>
        </Table>}

        </React.Fragment>
    );
}
import React, {useEffect, useState, useCallback} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';


import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
import Moment from 'react-moment';
import { Paper } from '@material-ui/core';




const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  margin: {
    margin: theme.spacing(0),
    padding: 16
  },
}));

export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [employeeData, setAttendanceData] = useState([]);

    const [department, setDepartment] = useState('');
    const [range, setRange] = useState('');
    const [rangeKey, setRangeKey] = useState('');
    const [rangeData, setRangeData] = useState([]);

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {

    }, []);

    async function getEmployeesReport(data = {}){
        let params = {
            'year_month': moment().format('YYYY-MM')
        };
        if(data['year_month']){
            params['year_month'] = data['year_month'];
        }
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getSummaryReport', params, headers);
        if(response.result){
            setAttendanceData(response.data);
        }else{
            
        }
    }

    const handleMonthChange = async (event) => {

        setRange(event.target.value);
        let selectedItem = rangeData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0]['key']){
            setRangeKey(selectedItem[0]['key']);
            let params: any = {};
            if(selectedItem[0]['key'] != ''){
                params.year_month = selectedItem[0]['key'];
            }
            await getEmployeesReport(params);
        }


    };

    const renderRange = () =>{
        if(rangeData.length > 0){
            return (rangeData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }

    };

    const handleCreate = () => {

    };
    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Account
            </Typography>
            <Grid className={classes.root}>
                <Grid item>
                    <FormControl className={classes.formControl}>
                        <TextField label="Admin Name" type="text" variant="outlined" fullWidth value={fullname} onChange={e => setFullname(e.target.value)}  />
                        <TextField label="Admin Name" type="text" variant="outlined" fullWidth value={password} onChange={e => setPassword(e.target.value)}  />
                        <TextField label="Admin Name" type="text" variant="outlined" fullWidth value={email} onChange={e => setEmail(e.target.value)}  />
                        <Button onClick={handleCreate}                                      variant="contained"
                            color="primary"
                            size="large"
                            className={classes.margin} fullWidth >
                        Create
                    </Button>
                    </FormControl>
                
                  
                </Grid>  
                
            </Grid>
       
        </React.Fragment>
    );
}
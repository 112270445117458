import React, {useEffect, useState, useCallback} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { CSVLink, CSVDownload } from "react-csv";

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
import Moment from 'react-moment';

const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  margin: {
    margin: theme.spacing(0),
    padding: 16
  }
}));

const headers = [
    { label: "Name", key: "name" },
    { label: "Time Check", key: "time" },
    { label: "type", key: "type" },
    { label: "Date", key: "date"}
];
   
export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();

    const [attendanceData, setAttendanceData] = useState([]);
    const [member, setMember] = useState('');

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [range, setRange] = useState('');
    const [rangeKey, setRangeKey] = useState('');
    const [rangeData, setRangeData] = useState([]);

    const [attendanceDownloadData, setAttendanceDownloadData] = useState([

    ]);

    
    useEffect(() => {
        
        if(origin == 'dashboard'){

        }else if (origin == 'attendance'){
            params['date_from'] = moment().startOf('month').format('YYYY-MM-DD');
            params['date_to'] = moment().set('date', '30').format('YYYY-MM-DD');
            
            setLabelWidth(inputLabel.current.offsetWidth);
            var end = moment().format("MM");
            // console.log(end);
            // console.log(moment().format("MMMM"));
            let dateCollection = [];
            for(let i =0; i <= parseInt(end); i++){
                dateCollection[i] = { key: moment().set({month: i}).format("YYYY-MM") , value: moment().set({month: i}).format("MMMM-YYYY")};
            }
            setRangeData(dateCollection);
        }
        getAttendanceReport(params);
    }, []);

    async function getAttendanceReport(data = {}){
        let params = {
        };
        if(data['date_from']){
            params['date_from'] = data['date_from'];
        }
        if(data['date_to']){
            params['date_to'] = data['date_to'];
        }
        if(data['year_month']){
            params['year_month'] = data['year_month'];
        }
        if(data['member']){
            params['member'] = data['member'];
        }
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getAttendanceReport', params, headers);


        if(response.result){
            setAttendanceData(response.data);
            // console.log(response.data);
            let { data } = response;
            setDLData(data);
        }else{
            setAttendanceData([]);
        }

    }

    const setDLData = (data) => {
        let download_data = [];
        for(let i in data){
            download_data.push({
                name: `${data[i]['firstname']} ${data[i]['lastname']}`,
                type: data[i]['type'],
                date: data[i]['date'],
                time: data[i]['time_check']
            })
        }
        setAttendanceDownloadData(download_data);
    }

    function navigateAttendance(event){
        event.preventDefault();
        history.push('/admin/attendance');
    }

    let tmpDate = moment().format('YYYY-MM-DD');

    // RENDERING OF ATTENDANCE TABLE
    function renderAttendance(){

        if(attendanceData.length > 0){
            return (
                attendanceData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                    <TableCell>
                        <Link href="#" onClick={preventDefault}>
                            {row.firstname} {row.lastname}
                        </Link>
                    </TableCell>
                    <TableCell>{moment(`${tmpDate} ${row.time_check}`).format("HH:mm A")}</TableCell>
                    <TableCell>{row.working_type_name}</TableCell>
                    <TableCell>
                    <Typography variant="caption" color={row.type == 'IN' ? 'primary': 'secondary'}>
                        {row.type }
                    </Typography>
                      
                    </TableCell>
                    <TableCell align="right">
                        {/*hh:mm A*/}
                        {moment(row.date_created).format("MMM D, Y") }
                        {/* <Moment format="MMM D, Y hh:mm A">
                            {row.date}
                        </Moment> */}
                    </TableCell>
                    </TableRow>
                ))
            );
        }
        return (<TableRow colSpan={20}>No result found.</TableRow>);
    }

    
    const handleMonthChange = event => {

        setRange(event.target.value);
        let selectedItem = rangeData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0]['key']){
            setRangeKey(selectedItem[0]['key']);
        }
    };

    const renderRange = () =>{
        if(rangeData.length > 0){
            return (rangeData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }

    };

    const searchRequest = useCallback(async () => {
        let params: any = {};
        if(member != ''){
            params.member = member;
        }
        if(rangeKey != ''){
            params.year_month = rangeKey;
        }
        await getAttendanceReport(params);
    }, [member, rangeKey]) ;


    return (
        <React.Fragment >
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Attendance
        </Typography>
        {origin == "attendance" ? (
            <Grid container spacing={3}>

                <Grid item lg={12} md={12} xs={8}>
                    <FormControl className={classes.formControl}>
                        <TextField id="outlined-search" label="Search..." type="search" variant="outlined" fullWidth onChange={e => setMember(e.target.value)} />
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-month-native" fullWidth>
                            Month
                        </InputLabel>
                        <Select
                        native
                        value={range}
                        onChange={handleMonthChange}
                        labelWidth={labelWidth}
                        inputProps={{
                            name: 'range',
                            id: 'outlined-month-native',
                        }}
                        >
                        <option aria-label="None" value="" />
                        {renderRange()}
                        
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.margin}
                            onClick={searchRequest}
                        >
                            <SearchIcon ></SearchIcon>
                        </Button> 
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        {/* <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.margin}
                        > */}
                            <CSVLink 
                                data={attendanceDownloadData} 
                                headers={headers} 
                                filename={"attendance-report.csv"}
                                className={classes.margin}>
                                {/* <CloudDownloadOutlinedIcon ></CloudDownloadOutlinedIcon> */}
                                Download CSV
                            </CSVLink>
{/*                            
                        </Button>   */}
                        
                    </FormControl>
                </Grid>
            </Grid>
        ) : null}
        <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>NAME</TableCell>
                <TableCell className={classes.tableHeader}>TIME CHECK</TableCell>
                <TableCell className={classes.tableHeader}>WORKING TYPE</TableCell>
                <TableCell className={classes.tableHeader}>TYPE</TableCell>
                <TableCell align="right" className={classes.tableHeader}>DATE</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderAttendance()
            }
            </TableBody>
        </Table>
        {origin == "dashboard" ? (<div className={classes.seeMore}>
            <Link color="primary" href="#" onClick={navigateAttendance}>
            See more
            </Link>
        </div>) : null}
        </React.Fragment>
    );
}
import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

// failed: '#f15d5e',
const colors = {
    success: '#45c49e',
    failed: '#4385f5',
    info: '#4385f5'
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
      height: 140,
      width: '100%',
      backgroundColor: colors.info,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
  },
  content: {
    padding: theme.spacing(2, 4, 3),
    width: 320,
    textAlign: 'center'
    
  },
  title:{
      fontWeight: 500
  },
  textContainer: {
      fontSize: 14,
      fontWeight: 400,
      marginTop: 10
  },
  buttonContainer:{
    paddingTop: 16,
    marginTop: 6,
    paddingBottom: 10
  },
  closeButton:{
  }
  

}));

/**
 *  Type
 *  - success
 *  - error
 *  - warning
 *  - info
 * 
*/
export default function (props){

    const [messageType, setMessageType] = useState(props.type ? props.type : "info");
    const [result, setResult] = useState(props.result);
    const [message, setMessage] = useState(props.message);
    const classes = useStyles();

    useEffect(() => {

        setResult(props.result);
        setMessageType(props.type);
        setMessage(props.message);

    }, [props.result, props.message, props.type])

    const renderIcon = () => {
        if(messageType == 'success'){
            return ( <CheckCircleOutlinedIcon style={{fontSize: 110, paddingTop: 24, color: '#fff'}} />);
        }else{
            return ( <InfoOutlinedIcon style={{fontSize: 110, paddingTop: 24, color: '#fff'}} />);
            // return ( <CancelOutlinedIcon style={{fontSize: 110, paddingTop: 24, color: '#fff'}} />);
        }

        return null;
    }
    
    const renderHeaderTitle = () => {
        if(result == true){
            return "Success!";
        }else{
            return "Failed!";
        }

        return props.headerTitle ? props.headerTitle : "Info";
    }

    return (
        <div>
            {/* Header */}
            <div id="server-modal-title" className={classes.header} style={{backgroundColor: result ? colors.success : !result ? colors.failed: null}}>
               {renderIcon()}
            </div>
            {/* Content */}
            <div id="server-modal-description" className={classes.content}>
                <div>
                    <Typography variant="h5" color="textSecondary" className={classes.title}>
                        {renderHeaderTitle()}
                    </Typography>

                    <div className={classes.textContainer}>
                        {message}
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.closeButton}  startIcon={<CloseIcon />} onClick={props.handleClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  }
}));

export default function (props) {
  const classes = useStyles();

  return (

    <React.Fragment>
      {props.loading === true ? (<LinearProgress color="primary" />) : null }
    </React.Fragment>
  );
}
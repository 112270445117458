import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  customButton:{
      marginLeft: 10,
      marginTop: 5,
      marginBottom: 5
  }
});

const quickLinksData = [
    {
        name: "Attendance",
        link: "#"
    },
    {
        name: "Employees",
        link: "#"
    },
    {
        name: "Settings",
        link: "#"
    },
    {
        name: "Reports",
        link: "#"
    },
];

export default function Shortcuts() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Quick Links
        </Typography>
      <div >
      {quickLinksData.map((row, key) => (

            <Button key={key} className={classes.customButton} variant="contained" color="primary">
                    {row.name}
                </Button>
        ))
      }
        

      </div>
    </React.Fragment>
  );
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import MessageModal from './MessageModal';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper
  },
}));

export default function CustomModal(props) {
  const classes = useStyles();

  const [result, setResult] = useState(props.result);
  const [message, setMessage] = useState(props.message);

  const [open, setOpen] = useState(props.open);
  const rootRef = React.useRef(null);

  useEffect(() => {
    setOpen(props.open);
    setMessage(props.message);
    setResult(props.result);
  }, [props.open, props.message, props.result])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const onClose = () =>{
      // do something
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        container={() => rootRef.current}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <Fade in={open}>
            <div className={classes.paper}>
                {props.type == 'message' ? (<MessageModal handleClose={handleClose} result={result} type={props.messageType ? props.messageType : (result == true ? "success" : "error") } message={message}/>) : null }
                {props.children}
            </div>
        </Fade>
      </Modal>
    </div>
  );
}
import React, {useEffect, useState, useCallback} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';


import Grid from '@material-ui/core/Grid';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { httpPost } from '../network/service';
import { useHistory } from "react-router-dom";

import Settings from '../config/settings';

import { randomId } from '../support/helper';
 
import Moment from 'react-moment';
import { Paper } from '@material-ui/core';




const moment = require('moment');

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  tableHeader: {
      fontWeight: 'bold'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  margin: {
    margin: theme.spacing(0),
    padding: 16
  },
}));

export default function ({origin, params}) {

    const classes = useStyles();
    const history = useHistory();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [employeeData, setAttendanceData] = useState([]);

    const [department, setDepartment] = useState('');
    const [range, setRange] = useState('');
    const [rangeKey, setRangeKey] = useState('');
    const [rangeData, setRangeData] = useState([]);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        getEmployeesReport(params);
        var end = moment().format("MM");
        // console.log(end);

        let dateCollection = [];
        for(let i =0; i <= parseInt(end); i++){
            dateCollection[i] = { key: moment().set({month: i}).format("YYYY-MM") , value: moment().set({month: i}).format("MMMM-YYYY")};
        }
        setRangeData(dateCollection);
    }, []);

    async function getEmployeesReport(data = {}){
        let params = {
            'year_month': moment().format('YYYY-MM')
        };
        if(data['year_month']){
            params['year_month'] = data['year_month'];
        }
        let headers = {
            'Authorizationkey' : Settings.token
        };
        let response = await httpPost('/admin/getSummaryReport', params, headers);
        if(response.result){
            setAttendanceData(response.data);
        }else{
            
        }
    }

    const handleMonthChange = async (event) => {

        setRange(event.target.value);
        let selectedItem = rangeData.filter(function(item) {
            return item.key == event.target.value
        });
        if(selectedItem[0]['key']){
            setRangeKey(selectedItem[0]['key']);
            let params: any = {};
            if(selectedItem[0]['key'] != ''){
                params.year_month = selectedItem[0]['key'];
            }
            await getEmployeesReport(params);
        }


    };

    const renderRange = () =>{
        if(rangeData.length > 0){
            return (rangeData.map((row)=>{
                return (<option  value={row.key}>{row.value}</option>);
            }));
        }

    };


    const handleSubmit = (evt) =>{
        evt.preventDefault();

    }

    // RENDERING OF ATTENDANCE TABLE
    function renderSummary(){

        if(employeeData.length > 0){
            return (
                employeeData.map(row => (
                    <TableRow key={randomId(20, 'aA0')}>
                        <TableCell>
                            <Link href="#" onClick={preventDefault}>
                                {row.firstname} {row.lastname}
                            </Link>
                        </TableCell>
                        <TableCell>{row.working_days || 0} / {row.total_working_days}</TableCell>
                        <TableCell>{row.late}</TableCell>
                        <TableCell>{row.overtime}</TableCell>
                        <TableCell>{row.absent}</TableCell>
                    </TableRow>
                ))
            );
        }
        return null;
    }
    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Breakdown
            </Typography>
            <Grid container spacing={3}>

                <Grid item lg={12} md={12} xs={8}>
 
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-department-native" fullWidth>
                            Month
                        </InputLabel>
                        <Select
                        native
                        value={range}
                        onChange={handleMonthChange}
                        labelWidth={labelWidth}
                        inputProps={{
                            name: 'department',
                            id: 'outlined-department-native',
                        }}
                        >
                        <option aria-label="None" value="" />
                        {renderRange()}
                        </Select>
                    </FormControl>


                    {/* <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel} htmlFor="outlined-range-native" fullWidth>
                            Range
                        </InputLabel>
                        <Select
                        native
                        value={range}
                        onChange={handleRangeChange}
                        labelWidth={labelWidth}
                        inputProps={{
                            name: 'range',
                            id: 'outlined-range-native',
                        }}
                        >
                        <option aria-label="None" value="" />
                        <option value={"full"}>Full</option>
                        <option value={"half"}>Half</option>

                        </Select>
                    </FormControl> */}

                </Grid>  
                
            </Grid>
        <Table size="medium">
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>NAME</TableCell>
                <TableCell className={classes.tableHeader}>DAYS</TableCell>
                <TableCell className={classes.tableHeader}>LATE</TableCell>
                <TableCell className={classes.tableHeader}>OT</TableCell>
                <TableCell className={classes.tableHeader}>ABSENT</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
            renderSummary()
            }
            </TableBody>
        </Table>
        </React.Fragment>
    );
}
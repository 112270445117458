import axios from "axios";
import Settings from "../config/settings";


let axiosService =  axios.create({
  baseURL: Settings.url,
  responseType: "json"
});

async function httpPost(prefix, requestData: any = {}, headers){
    try{
        let response = await axiosService.post(prefix, JSON.stringify(requestData), {headers: {...headers, ...{'Content-Type': 'application/json'} }});
        
        // SUCCESS
        if(response.status === 200){
            return response.data;
        }else{
            return {result: false, message: 'Failed to get data.'}
        }

    }catch(e){
        return {result: false, message: `Failed to make request ${e.message}.`};
    }

}

export { httpPost };



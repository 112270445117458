import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';


import OESPH_ICON from '../assets/img/logo.png';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  logo:{
      marginTop: '20vh',
      height: 240,
      width: 240
  }

}));

export default function Landing() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <CssBaseline />
        <Container component="main" className={classes.main} maxWidth="sm">
            <img className={classes.logo} src={OESPH_ICON}/>
            <Typography variant="h6" gutterBottom>
                OESPH
            </Typography>
            <Link href="/admin/login">Redirect to Login</Link>
        </Container>
    </div>
  );
}
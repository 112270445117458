import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Link from '@material-ui/core/Link';

import Collapse from '@material-ui/core/Collapse';

import { useHistory } from "react-router-dom";

import PeopleIcon from '@material-ui/icons/People';

import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';




export default function (){

  return (
    <React.Fragment>
    <ListItem button component="a" href="/admin/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component="a" href="/admin/employees">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Employees" />
    </ListItem>
    <ListItem button component="a" href="/admin/attendance">
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Attendance" />
    </ListItem>
    
    <ListItem button component="a" href="/admin/department">
      <ListItemIcon>
        <SettingsApplicationsIcon />
      </ListItemIcon>
      <ListItemText primary="Department" />
    </ListItem>
    <ListItem  button component="a" href="/admin/attendance-breakdown" >
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Summary" />
    </ListItem>
    {/* <ListItem  button component="a" href="/admin/accounts" >
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts" />
    </ListItem> */}
    <ListItem button component="a" href="/admin/breakdown">
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Breakdown" />
    </ListItem>

  </React.Fragment>

  );
}

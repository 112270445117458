import React, {useState, useEffect} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  withRouter,
  Redirect
} from "react-router-dom";

import { createBrowserHistory } from "history";
import { CookiesProvider } from 'react-cookie';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SideMenu from './components/SideMenu';
import Logout from './components/Logout';


// PAGES
import AdminLogin from './pages/AdminLogin';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import AttendanceReport from './pages/AttendanceReport';
import SummaryReport from './pages/SummaryReport';
import EmployeeReport from './pages/EmployeeReport';
import AttendanceNew from './pages/AttendanceNewReport';

import Department from './pages/Department';

import { useCookies } from 'react-cookie';


import Settings from './config/settings';
import AdminAccountReport from './pages/AdminAccountReport';


const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textTransform: 'capitalize'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const excludedPages = [
  "login",
  "logout"
];

// const  LogoutComponent = (props) =>{
//   return(
//     <RouterLink to="/admin/login" replace />
//   )
// }


export default function App() {

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [currentPage, setPage] = useState("");
  const [insidePage, setPageState] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['admin_session']);
  const history = createBrowserHistory();

  useEffect(()=>{
    var currentLocation = window.location.pathname.replace("/admin/","");

    if(!excludedPages.includes(currentLocation)){
      setPageState(true);
      Settings.token = cookies.admin_session;
    }
    setPage(currentLocation);

  }, [currentPage]);

  const handleDrawerOpen = () => {
      setOpen(true);
  };

  const handleDrawerClose = () => {
      setOpen(false);
  };

  const deleteSession = () => {
    console.log('delete session');
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    
    <div style={{flex: 1}}>
      <CookiesProvider>
        {/* Start Of Router */}
        <Router history={history}  forceRefresh={true}>
         {/* <Redirect from='/' to='/admin/login' /> */}
          <Switch>
          
            <Route exact={true} path="/" component={Landing} />
            {/* <Router path="/" component={<Redirect to="admin/login"/>}/> */}
              {/* <Redirect from='/admin/logout' to='/admin/login' /> */}
              <Route exact path="/admin/login">
                <AdminLogin />
              </Route>
              {/* <Router path="/admin/logout" component={<Redirect to="admin/login"/>}/> */}
              {insidePage == true ? (
              <div className={classes.root} >
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {currentPage}
                    </Typography>
                    {/* <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                        </Badge>
                    </IconButton> */}
                    </Toolbar>
                </AppBar>
                <Drawer
                      variant="permanent"
                      classes={{
                      paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                      }}
                      open={open}
                  >
                    <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                    </div>
                    <Divider />
                    <List>{<SideMenu />}</List>
                    <Divider />
                    <Divider />

                    <List>{<Logout />}</List>

                </Drawer>

                  {cookies.admin_session ? (<div>
                    <Route exact path="/admin/dashboard" name="Dashboard" component={Dashboard} />
                    <Route exact path="/admin/attendance-breakdown" component={AttendanceReport} />
                    <Route exact path="/admin/breakdown" component={SummaryReport} />
                    <Route exact path="/admin/employees" component={EmployeeReport} />
                    <Route exact path="/admin/department" component={Department} />
                    <Route exact path="/admin/attendance" component={AttendanceNew} />
                    <Route exact path="/admin/accounts" component={AdminAccountReport} />
                  </div>) : (<Redirect from='/admin/logout' to='/admin/login' />)}

                  {/* <Route exact path="/admin/logout">
                    <Redirect from='/admin/logout' to='/admin/login' />
                  </Route> */}
                  {/* <Route  exact path="/admin/logout" component={LogoutComponent} /> */}

                  <Route path="*" component={NotFound} />

              </div>) : <div computedMatch={undefined}></div>}

            </Switch>
            
        </Router>
        {/* End Of Router */}
      </CookiesProvider>
    </div>
  );
}

function NotFound(){

  return (
    <div>Page not Found</div>
  );
}